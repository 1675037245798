import React from "react"
import { ContentSection } from "./components"

export const AboutScreen = () => (
  <>
    <div className="site-main">
      <ContentSection />
    </div>
  </>
)
